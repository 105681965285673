import { css, Theme } from "@emotion/react";

export const Field = ({ theme }: { theme: Theme }) => css`
  border-radius: 16px;
  border: 2px solid rgba(26, 25, 45, 0.05);
  // background: rgba(26, 25, 45, 0.03);
  padding: 14px 12px;
  font-family: "HafferXH";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.14px;
  outline: none;
  color: ${theme.__new.colors.contentPrimary};
  display: block;
  width: 100%;
  &:focus {
    border: 2px solid ${theme.__new.colors.contentAccent};
  }
`;
